import React from "react"
import { Container, Row } from "react-bootstrap"

const PrivacyPolicyRo = (): JSX.Element => (
  <Row className="bg-white privacy-page pt-3 pb-5" noGutters>
    <Container className="py-5">
      <div className="text-block">
        <p>
          Confidențialitatea dvs. este importantă pentru noi. Este politica
          Media Radar, gestionat de Centrul pentru Jurnalism Independent (CJI),
          de a respecta confidențialitatea dvs. cu privire la orice informații
          pe care le-am putea colecta de la dvs. pe site-ul nostru web,{" "}
          <a href="http://radar.mediacritica.md">
            radar.mediacritica.md
          </a>{" "}
          și pe alte site-uri pe care le deținem și pe care le operăm.
        </p>
        <p className="mt-4">
          Solicităm informații personale doar atunci când avem nevoie cu
          adevărat de acestea pentru a vă oferi un serviciu solicitat. Le
          colectăm prin mijloace corecte și legale, cu cunoștința și
          consimțământul dvs. Vă informăm, de asemenea, de ce le colectăm și cum
          vor fi folosite.
        </p>
        <p className="mt-4">
          Păstrăm informațiile colectate doar atât timp cât este necesar pentru
          a vă oferi serviciul solicitat. Stocăm, protejăm datele în cadrul
          mijloacelor acceptabile din punct de vedere comercial pentru a preveni
          pierderea și furtul, precum și accesul, dezvăluirea, copierea,
          utilizarea sau modificarea neautorizate. <br />
          Nu împărtășim nicio informație personală de identificare publică sau
          cu terți, cu excepția cazului în care legea prevede acest lucru.
        </p>
        <p className="mt-4">
          Website-ul nostru se poate conecta la site-uri externe care nu sunt
          operate de noi. Vă rugăm să fiți conștienți că nu avem niciun control
          asupra conținutului și practicilor acestor site-uri și nu putem
          accepta responsabilitatea sau răspunderea pentru politicile lor
          private de confidențialitate.
        </p>
        <p className="mt-4">
          Sunteți liber să refuzați solicitarea noastră pentru informațiile dvs.
          personale, înțelegând că este posibil să nu vă putem oferi unele
          dintre serviciile dorite.
        </p>
        <p className="mt-4">
          Utilizarea dvs. continuă a site-ului nostru web va fi considerată o
          acceptare a practicilor noastre privind confidențialitatea și
          informațiile personale.
        </p>
        <p className="mt-4">
          Dacă aveți întrebări despre cum gestionăm datele utilizatorului și
          informațiile personale, nu ezitați să ne contactați.
        </p>
      </div>
    </Container>
  </Row>
)

export default PrivacyPolicyRo
