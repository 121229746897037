import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import { useIntl } from "gatsby-plugin-intl"
import PrivacyPolicyRu from "../components/privacy.ru"
import PrivacyPolicyRo from "../components/privacy.ro"

const PrivacyPolicyPage = (): JSX.Element => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: "privacy.subtitle" })}></SEO>
      <Header subtitle={intl.formatMessage({ id: "privacy.subtitle" })} />
      {intl.locale === "ru" ? <PrivacyPolicyRu /> : <PrivacyPolicyRo />}
    </Layout>
  )
}

export default PrivacyPolicyPage
