import React from "react"
import { Container, Row } from "react-bootstrap"

const PrivacyPolicyRu = (): JSX.Element => (
  <Row className="bg-white privacy-page pt-3 pb-5" noGutters>
    <Container className="py-5">
      <div className="text-block">
        <p>
          Ваша конфиденциальность важна для нас. Политика Media Radar,
          управляемая Centrul pentru Jurnalism Independent (CJI), заключается в
          уважении вашей конфиденциальности в отношении любой информации,
          которую мы можем получить от вас на нашем веб-сайте{" "}
          <a href="http://radar.mediacritica.md">
            radar.mediacritica.md
          </a>{" "}
          и другие сайты, которыми мы владеем и управляем.
        </p>
        <p className="mt-4">
          Мы запрашиваем личную информацию только тогда, когда она нам
          действительно необходима для предоставления вам запрошенной услуги. Мы
          собираем их справедливым и законным путем, с вашего ведома и согласия,
          а также информируем вас о том, почему мы собираем их и как они будут
          использоваться.
        </p>
        <p className="mt-4">
          Мы храним собранную информацию только столько времени, сколько
          необходимо для предоставления вам запрошенной услуги. Мы храним,
          защищаем данные с помощью коммерчески приемлемых средств для
          предотвращения потери и кражи, а также несанкционированного доступа,
          разглашения, копирования, использования или изменения. <br />
          Мы не передаем какую-либо личную информацию публично или третьим
          лицам, если это не требуется по закону.
        </p>
        <p className="mt-4">
          Наш веб-сайт может подключаться к внешним сайтам, которые не
          управляются нами. Пожалуйста, имейте в виду, что мы не контролируем
          содержание и практику этих сайтов и не можем принять на себя
          ответственность за их политику конфиденциальности.
        </p>
        <p className="mt-4">
          Вы можете отклонить наш запрос о предоставлении вашей личной
          информации при том понимании, что мы не сможем предоставить вам
          некоторые из необходимых нам услуг.
        </p>
        <p className="mt-4">
          Ваше дальнейшее использование нашего веб-сайта будет считаться
          принятием наших правил конфиденциальности и личной информации.
        </p>
        <p className="mt-4">
          Если у вас есть какие-либо вопросы о том, как мы обрабатываем
          пользовательские данные и личную информацию, пожалуйста, не
          стесняйтесь обращаться к нам.
        </p>
      </div>
    </Container>
  </Row>
)

export default PrivacyPolicyRu
